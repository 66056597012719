<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}} 
					<Tag v-if="$store.getters.isRegionalUser" :value="$store.getters.regionalUser.role.name + (($store.getters.regionalUser.province) ? ' : ' + $store.getters.regionalUser.province.name : '') + (($store.getters.regionalUser.regency) ? ', ' + $store.getters.regionalUser.regency.name : '') + (($store.getters.regionalUser.district) ? ', ' + $store.getters.regionalUser.district.name : '') + (($store.getters.regionalUser.village) ? ', ' + $store.getters.regionalUser.village.name : '')" class="mr-1 mb-1" />
                </template>
                <template #content>
					<!-- Toolbar -->
					<Toolbar class="mb-4">
						<template #start>
							<Button v-if="$can('member-add')" label="Tambah" icon="pi pi-plus" class="p-button-primary mr-2" @click="openNew" />
							<Button v-if="$can('member-delete')" label="Hapus" icon="pi pi-trash" class="p-button-danger" 
								@click="openDelete('multi')" :disabled="!dataTable.selectedData || !dataTable.selectedData.length" />
						</template>
					</Toolbar>

					<!-- Data Table -->
					<DataTable class="p-datatable-gridlines p-datatable-sm" filterDisplay="menu" :rowHover="true" :lazy="true" :paginator="true" scrollHeight="flex"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
						v-model:filters="dataTable.params.filters"
						v-model:selection="dataTable.selectedData" dataKey="id"
						:rowsPerPageOptions="dataTable.rows_option"
						:value="dataTable.data"
						:rows="dataTable.params.rows" 
						:totalRecords="dataTable.totalRecords" 
						:loading="dataTable.loading" 
						@page="loadData($event)" @sort="loadData($event)">
						<template #header>
							<div class="flex justify-content-between flex-column sm:flex-row">
								<div class="p-fluid">
									<MultiSelect v-model="dataTable.selectedColumns" :options="dataTable.columns"  optionValue="field" optionLabel="header" placeholder="Select Columns" display="chip" style="width: 20em"/>
								</div>
								<span class="p-input-icon-left mb-2">
									<i class="pi pi-search" />
									<InputText v-model="dataTable.params.filters['global'].value" @keydown.enter="loadData()" :placeholder="dataTable.global_placeholder" v-tooltip.top.focus="'Press Enter'" style="width: 100%"/>
								</span>
							</div>
						</template>
						<template #empty>
							<div class="p-text-center">No data found.</div>
						</template>
						<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
						<!-- Columns -->
						<Column v-for="(col, i) of dataTable.columns.filter(col => dataTable.selectedColumns.includes(col.field))" :field="col.field" :header="col.header" :sortable="col.sort" :showFilterMatchModes="false" :key="i">
							<template #body="{data}">
								<div v-if="col.default">{{ data[col.field] }}</div>
								<div v-else-if="col.field == 'province'">{{ (data.province) ? data.province.name : null }}</div>
								<div v-else-if="col.field == 'regency'">{{ (data.regency) ? data.regency.name : null }}</div>
								<div v-else-if="col.field == 'district'">{{ (data.district) ? data.district.name : null }}</div>
								<div v-else-if="col.field == 'village'">{{ (data.village) ? data.village.name : null }}</div>
								<div v-else-if="col.field == 'created_at'">{{ $filter_date(data.created_at) }}</div>
								<div v-else-if="col.field == 'bukti_transfer'">
									<Tag v-if="data.bukti_transfer == null" severity="warning" class="mr-1"><i class="pi pi-times"></i></Tag>
									<Tag v-else-if="data.bukti_transfer != null" severity="primary" class="mr-1"><i class="pi pi-check"></i></Tag>
								</div>
								<div v-else-if="col.field == 'status'">
									<Tag v-if="data.status == 0" severity="warning" value="Belum Dirverifikasi" class="mr-1" />
									<Tag v-else-if="data.status == 1" severity="primary" value="Terverifikasi" class="mr-1" />
								</div>
								<div v-else-if="col.field == 'action'">
									<Button v-if="$can('member-list')" @click="openView(data)" v-tooltip.top="'Lihat'" icon="pi pi-eye" class="p-button-rounded p-button-text p-button-secondary mr-2" />
									<Button v-if="$can('member-edit')" @click="openEdit(data)" v-tooltip.top="'Edit'" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary mr-2" />
									<Button v-if="$can('member-delete')" @click="openDelete('single', data)" v-tooltip.top="'Hapus'" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
								</div>
							</template>

							<template v-if="col.filter" #filter="{filterModel,filterCallback}">
								<InputText v-if="col.default" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="column-filter" placeholder="Search" />
								<Dropdown v-else-if="col.field == 'province'" :options="relations.provinces" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="true" placeholder="Select" />
								<Dropdown v-else-if="col.field == 'regency'" :options="relations.regencies" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="true" placeholder="Select" />
								<Dropdown v-else-if="col.field == 'district'" :options="relations.districts" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="true" placeholder="Select" />
								<Dropdown v-else-if="col.field == 'village'" :options="relations.villages" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="true" placeholder="Select" />
								<MultiSelect v-else-if="col.field == 'status'" :options="relations.status" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" placeholder="Select" />
								<Dropdown v-else-if="col.field == 'bukti_transfer'" :options="relations.bukti_transfer" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="false" placeholder="Select" />
							</template>
						</Column>
						<!-- End Columns -->
					</DataTable>

					<!-- Detail -->
					<Dialog v-model:visible="dialog.dataView" :header="title" :style="{width: '650px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<DataTable :value="dataView" responsiveLayout="scroll" :scrollable="true">
							<Column field="title"></Column>
							<Column field="content">
								<template #body="{data}">
									<div v-if="data.type == 'default'">: {{ data.content }}</div>
									<div v-else-if="data.type == 'status'">
										<Tag v-if="data.content == 0" severity="warning" value="Belum Dirverifikasi" class="mr-1" />
										<Tag v-else-if="data.content == 1" severity="primary" value="Terverifikasi" class="mr-1" />
									</div>
									<div v-else-if="data.type == 'pasfoto'">
										<Image :src="(data.content) ? assets_folder + '/member_pasfoto/' + data.content : no_image" imageStyle="width: 100px;" preview />
									</div>
									<div v-else-if="data.type == 'foto_ktp'">
										<Image :src="(data.content) ? assets_folder + '/member_foto_ktp/' + data.content : no_image" imageStyle="width: 300px;" preview />
									</div>
									<div v-else-if="data.type == 'bukti_transfer'">
										<div class="field">
											<Button v-if="!dialog.comment && data.content && $can('member-verify')" label="Komentari" icon="pi pi-comment" class="p-button-sm p-button-primary mr-2" @click="commentDialog(true)" />
											<div v-if="dialog.comment && data.content">
												<div class="field">
													<label>Pesan</label>
													<Textarea v-model="commentForm.pesan"
														:class="{ 'p-invalid': commentForm.errors.has('pesan') }" />
													<small class="p-error" v-show="commentForm.errors.has('pesan')">
														{{ commentForm.errors.get('pesan') }}
													</small>
												</div>
												<div class="grid">
													<div class="col-6">
														<Button label="Save" @click="submitComment()" icon="pi pi-check" class="p-button-sm p-button-outlined" />
													</div>
													<div class="col-6">
														<Button label="Close" @click="commentDialog(false)" icon="pi pi-times" class="p-button-sm p-button-outlined" />
													</div>
												</div>
											</div>
										</div>
										<Image :src="(data.content) ? assets_folder + '/member_bukti_transfer/' + JSON.parse(data.content).file : no_image" imageStyle="width: 300px;" preview />
									</div>
								</template>
							</Column>
						</DataTable>

						<template #footer>
							<Button v-if="dataViewId.status == 0 && $can('member-verify')" label="Verifikasi" @click="verifyMember(dataViewId.id)" icon="pi pi-check" class="p-button-text" style="float:right" />
							<Button v-else-if="dataViewId.status == 1 && $can('member-verify')" label="Menangguhkan" @click="verifyMember(dataViewId.id)" icon="pi pi-times" class="p-button-text" style="float:right" />
							<Button label="Close" @click="dialog.dataView = false" icon="pi pi-times" class="p-button-text" />
						</template>
					</Dialog>

					<!-- Form -->
					<Dialog v-model:visible="dialog.formData" :header="formDataTitle" :style="{width: '650px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="grid">
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Nama</label>
									<InputText v-model="dataForm.nama" autofocus
										:class="{ 'p-invalid': dataForm.errors.has('nama') }" />
									<small class="p-error" v-show="dataForm.errors.has('nama')">
										{{ dataForm.errors.get('nama') }}
									</small>
								</div>
							</div>
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>NTA</label>
									<InputText v-model="dataForm.nta"
										:class="{ 'p-invalid': dataForm.errors.has('nta') }" />
									<small class="p-error" v-show="dataForm.errors.has('nta')">
										{{ dataForm.errors.get('nta') }}
									</small>
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>NIK</label>
									<InputText v-model="dataForm.nik"
										:class="{ 'p-invalid': dataForm.errors.has('nik') }" />
									<small class="p-error" v-show="dataForm.errors.has('nik')">
										{{ dataForm.errors.get('nik') }}
									</small>
								</div>
							</div>
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Nama Instansi</label>
									<InputText v-model="dataForm.nama_instansi"
										:class="{ 'p-invalid': dataForm.errors.has('nama_instansi') }" />
									<small class="p-error" v-show="dataForm.errors.has('nama_instansi')">
										{{ dataForm.errors.get('nama_instansi') }}
									</small>
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>No NUPTK/NIDN</label>
									<InputText v-model="dataForm.no_n"
										:class="{ 'p-invalid': dataForm.errors.has('no_n') }" />
									<small class="p-error" v-show="dataForm.errors.has('no_n')">
										{{ dataForm.errors.get('no_n') }}
									</small>
								</div>
							</div>
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Tanggal Lahir</label>
									<Calendar v-model="dataForm.tgl_lahir" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange"
										:class="{ 'p-invalid': dataForm.errors.has('tgl_lahir') }" />
									<small class="p-error" v-show="dataForm.errors.has('tgl_lahir')">
										{{ dataForm.errors.get('tgl_lahir') }}
									</small>
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>No HP</label>
									<InputText v-model="dataForm.no_hp"
										:class="{ 'p-invalid': dataForm.errors.has('no_hp') }" />
									<small class="p-error" v-show="dataForm.errors.has('no_hp')">
										{{ dataForm.errors.get('no_hp') }}
									</small>
								</div>
							</div>
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Email</label>
									<InputText v-model="dataForm.email"
										:class="{ 'p-invalid': dataForm.errors.has('email') }" />
									<small class="p-error" v-show="dataForm.errors.has('email')">
										{{ dataForm.errors.get('email') }}
									</small>
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Password</label>
									<Password v-model="dataForm.password" mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})." toggleMask
										:class="{ 'p-invalid': dataForm.errors.has('password') }">
										<template #header>
                                            <h6>Pilih kata sandi</h6>
                                        </template>
                                        <template #footer>
                                            <Divider />
                                            <p class="mt-2">Saran</p>
                                            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                                <li>Setidaknya satu huruf kecil</li>
                                                <li>Setidaknya satu huruf besar atau satu angka</li>
                                                <li>Minimal 6 karakter</li>
                                            </ul>
                                        </template>
									</Password>
									<small class="p-error" v-show="dataForm.errors.has('password')">
										{{ dataForm.errors.get('password') }}
									</small>
								</div>
							</div>
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Confirm Password</label>
									<Password v-model="dataForm.password_confirmation" :feedback="false" toggleMask
										:class="{ 'p-invalid': dataForm.errors.has('password_confirmation') }" />
									<small class="p-error" v-show="dataForm.errors.has('password_confirmation')">
										{{ dataForm.errors.get('password_confirmation') }}
									</small>
								</div>
							</div>
						</div>
						<div class="field">
							<label>Alamat</label>
							<Textarea v-model="dataForm.alamat"
								:class="{ 'p-invalid': dataForm.errors.has('alamat') }" />
							<small class="p-error" v-show="dataForm.errors.has('alamat')">
								{{ dataForm.errors.get('alamat') }}
							</small>
						</div>
						<div class="grid">
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Provinsi</label>
									<Dropdown v-model="dataForm.province_id" optionValue="id" optionLabel="name" :filter="true"
										@change="getRegencies(dataForm.province_id, null, true)"
										:options="relations.provinces"
										:class="{ 'p-invalid': dataForm.errors.has('province_id') }" />
									<small class="p-error" v-show="dataForm.errors.has('province_id')">
										{{ dataForm.errors.get('province_id') }}
									</small>
								</div>
							</div>
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Kabupaten/Kota</label>
									<Dropdown v-model="dataForm.regency_id" optionValue="id" optionLabel="name" :filter="true"
										@change="getDistricts(dataForm.regency_id, null, true)"
										:options="relations.regencies"
										:class="{ 'p-invalid': dataForm.errors.has('regency_id') }" />
									<small class="p-error" v-show="dataForm.errors.has('regency_id')">
										{{ dataForm.errors.get('regency_id') }}
									</small>
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Kecamatan</label>
									<Dropdown v-model="dataForm.district_id" optionValue="id" optionLabel="name" :filter="true"
										@change="getVillages(dataForm.district_id)"
										:options="relations.districts"
										:class="{ 'p-invalid': dataForm.errors.has('district_id') }" />
									<small class="p-error" v-show="dataForm.errors.has('district_id')">
										{{ dataForm.errors.get('district_id') }}
									</small>
								</div>
							</div>
							<div class="sm:col-6 col-12">
								<div class="field">
									<label>Desa/Kelurahan</label>
									<Dropdown v-model="dataForm.village_id" optionValue="id" optionLabel="name" :filter="true"
										:options="relations.villages"
										:class="{ 'p-invalid': dataForm.errors.has('village_id') }" />
									<small class="p-error" v-show="dataForm.errors.has('village_id')">
										{{ dataForm.errors.get('village_id') }}
									</small>
								</div>
							</div>
						</div>
						<div class="field">
							<label>Status</label>
							<Dropdown v-model="dataForm.status" optionValue="id" optionLabel="name"
								:options="relations.status"
								:class="{ 'p-invalid': dataForm.errors.has('status') }" />
							<small class="p-error" v-show="dataForm.errors.has('status')">
								{{ dataForm.errors.get('status') }}
							</small>
						</div>

						<template #footer>
							<Button label="Save" @click="submitData" :disabled="dataForm.busy" :icon="(dataForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-text" style="float:right" />
							<Button label="Cancel" @click="dialog.formData = false" icon="pi pi-times" class="p-button-text" />
						</template>
					</Dialog>

					<!-- Delete Confirmation -->
					<Dialog v-model:visible="dialog.deleteData" header="Confirmation" :style="{width: '350px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="confirmation-content">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span>Do you want to delete this record?</span>
						</div>
						<template #footer>
							<Button label="No" icon="pi pi-times" @click="dialog.deleteData = false" class="p-button-text"/>
							<Button label="Yes" icon="pi pi-check" @click="deleteData" class="p-button-text" autofocus />
						</template>
					</Dialog>
				</template>
			</Card>

		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: 'Member',
			api: '/api/member',
			dataForm: new this.$FormAuth({
				mode: null,
				id: null,
				nama: null,
				nta: null,
				nik: null,
				nama_instansi: null,
				no_n: null,
				tgl_lahir: null,
				no_hp: null,
				email: null,
				password: null,
				password_confirmation: null,
				alamat: null,
				province_id: null,
				regency_id: null,
				district_id: null,
				village_id: null,
				status: null,
			}),
			commentForm: new this.$FormAuth({
				id: null,
				pesan: null,
			}),
			dataView: [],
			dataViewId: {},
			relations: {
				provinces: null,
				regencies: null,
				districts: null,
				villages: null,
				status: null,
				bukti_transfer: [
					{id: "1", name: 'Belum'},
					{id: "2", name: 'Sudah'},
				],
			},
			// regionalUser: this.$store.getters.regionalUser,
			dataTable: {
				data: [],
				loading: false,
				global_placeholder: 'Nama/NTA',
				totalRecords: 0,
				rows_option: [10, 25, 50, 100],
				selectedData: [],
				columns: [
					{field: 'nama', header: 'Nama', sort: true, filter: true, default: true},
					{field: 'nta', header: 'NTA', sort: true, filter: true, default: true},
					{field: 'nik', header: 'NIK', sort: true, filter: true, default: true},
					{field: 'bukti_transfer', header: 'Transfer', sort: false, filter: true},
					{field: 'status', header: 'Status', sort: false, filter: true},
					{field: 'province', header: 'Provinsi', sort: false, filter: (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.province) ? false : true},
					{field: 'regency', header: 'Kabupaten', sort: false, filter:  (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.regency) ? false : true},
					{field: 'district', header: 'Kecamata', sort: false, filter:  (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.district) ? false : true},
					{field: 'village', header: 'Desa', sort: false, filter:  (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.village) ? false : true},
					{field: 'created_at', header: 'Terdaftar', sort: true, filter: false},
					{field: 'action', header: 'Action', sort: false, filter: false},
				],
				selectedColumns: ['nama', 'nta', 'bukti_transfer', 'status', 'action'],
				params: {
					filters: {
						global: {value: null},
						nama: {value: null},
                        nta: {value: null},
						nik: {value: null},
						province: {value: (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.province) ? this.$store.getters.regionalUser.province.id : null},
						regency: {value: (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.regency) ? this.$store.getters.regionalUser.regency.id : null},
						district: {value: (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.district) ? this.$store.getters.regionalUser.district.id : null},
						village: {value: (this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.village) ? this.$store.getters.regionalUser.village.id : null},
						status: {value: null},
						bukti_transfer: {value: null},
					},
					rows: 10, //per_page
					page: 0,
					sortField: null,
					sortOrder: null,
				},
			},
			dialog: {
				dataView: false,
				formData: false,
				deleteData: false,
				comment: false,
			},
			formDataTitle: null,
			assets_folder: process.env.VUE_APP_ASSETS_FOLDER,
			no_image: process.env.VUE_APP_DEFAULT_NO_IMAGE
		}
	},
	watch: {
		'dataTable.params.filters': function(newVal, oldVal){
			this.loadData();

			if(newVal['province'].value != oldVal['province'].value){
				if(newVal['province'].value != null){
					this.getRegencies(this.dataTable.params.filters['province'].value, null, false);
				} else {
					this.relations.regencies = null;
				}
			} else if(newVal['regency'].value != oldVal['regency'].value){
				if(newVal['regency'].value != null){
					this.getDistricts(this.dataTable.params.filters['regency'].value, null, false);
				} else {
					this.relations.districts = null;
				}
			} else if(newVal['district'].value != oldVal['district'].value){
				if(newVal['district'].value != null){
					this.getVillages(this.dataTable.params.filters['district'].value, null);
				} else {
					this.relations.villages = null;
				}
			}
		},
    },
	computed: {
        yearRange() {
			const now = new Date().getFullYear();
			const past = now-100;
			const yearRange = past+':'+now;
            return yearRange;
        }
    },
	methods: {
		loadData(event = null){
			this.$Progress.start();
			this.dataTable.loading = true;
			(event) ? this.dataTable.params = event : null;
			// console.log(this.dataTable.params);
			this.$httpAuth.get(this.api, {
				params: {
					rows: this.dataTable.params.rows,
					sortField: this.dataTable.params.sortField,
					sortOrder: this.dataTable.params.sortOrder,
					filters: this.dataTable.params.filters,
					page: this.dataTable.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataTable.loading = false;
				this.dataTable.data = response.data.data;
				this.dataTable.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataTable.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

		getProvinces(current_id, resetTree = false){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/provinces')
			.then((response) => {
				this.$Progress.finish();
				this.relations.provinces = response.data;
				if(current_id == null){
					this.dataForm.province_id = null;
				} else {
					this.dataForm.province_id = current_id;
				}
				if(resetTree == true){
					this.dataForm.regency_id = null;
					this.dataForm.district_id = null;
					this.dataForm.village_id = null;
					this.relations.regencies = null;
					this.relations.districts = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getRegencies(province_id, current_id, resetTree = false){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/regencies', {
				params: {
					province_id: province_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.regencies = response.data;
				if(current_id == null){
					this.dataForm.regency_id = null;
				} else {
					this.dataForm.regency_id = current_id;
				}
				if(resetTree == true){
					this.dataForm.district_id = null;
					this.dataForm.village_id = null;
					this.relations.districts = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getDistricts(regency_id, current_id, resetTree = false){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/districts', {
				params: {
					regency_id: regency_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.districts = response.data;
				if(current_id == null){
					this.dataForm.district_id = null;
				} else {
					this.dataForm.district_id = current_id;
				}
				if(resetTree == true){
					this.dataForm.village_id = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getVillages(district_id, current_id){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/villages', {
				params: {
					district_id: district_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.villages = response.data;
				if(current_id == null){
					this.dataForm.village_id = null;
				} else {
					this.dataForm.village_id = current_id;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getStatus(){
            this.$httpAuth.get(this.api + '/get/status')
            .then((response) => {
                this.relations.status = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

		commentDialog(v){
			this.dialog.comment = v;
		},
		verifyMember(id){
			this.$Progress.start();
			this.$httpAuth.post(this.api + '/' + id + '/verify')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.dialog.dataView = false;
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			})
		},
		submitComment(){
			this.$Progress.start();
			this.commentForm.post(this.api + '/' + this.commentForm.id + '/transfer-comment')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.dialog.comment = false;
				this.dialog.dataView = false;
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},

		openNew() {
			this.resetForm();
			this.dataForm.mode = 'add';
			this.formDataTitle = 'Tambah '+this.title;
			this.getProvinces(null, true);
			this.dialog.formData = true;
		},
		openView(data){
			this.dataViewId = {
				id: data.id,
				status: data.status,
			};
			this.dialog.comment = false;
			this.commentForm.fill({
				id: data.id,
				pesan: (data.bukti_transfer) ? JSON.parse(data.bukti_transfer).pesan : null,
			})
			this.dataView = [
				{ title: "Pasfoto", content: data.pasfoto, type: 'pasfoto' },
				{ title: "Nama", content: data.nama, type: 'default' },
				{ title: "NTA", content: data.nta, type: 'default' },
				{ title: "NIK", content: data.nik, type: 'default' },
				{ title: "Nama Instansi", content: data.nama_instansi, type: 'default' },
				{ title: "No. NUPTK/NIDN", content: data.no_n, type: 'default' },
				{ title: "Tanggal Lahir", content: this.$filter_date(data.tgl_lahir), type: 'default' },
				{ title: "No HP", content: data.no_hp, type: 'default' },
				{ title: "Email", content: data.email, type: 'default' },
				{ title: "Alamat", content: data.alamat, type: 'default' },
				{ title: "Provinsi", content: (data.province) ? data.province.name : null, type: 'default' },
				{ title: "Kabupaten/Kota", content: (data.regency) ? data.regency.name : null, type: 'default' },
				{ title: "Kecamatan", content: (data.district) ? data.district.name : null, type: 'default' },
				{ title: "Desa/Kelurahan", content: (data.village) ? data.village.name : null, type: 'default' },
				{ title: "Terdaftar", content: this.$filter_date(data.created_at), type: 'default' },
				{ title: "Status", content: data.status, type: 'status' },
				{ title: "Foto KTP", content: data.foto_ktp, type: 'foto_ktp' },
				{ title: "Bukti Transfer", content: data.bukti_transfer, type: 'bukti_transfer' },
			];
			this.dialog.dataView = true;
		},
		openEdit(data){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/' + data.id + '/edit')
			.then((response) => {
				this.$Progress.finish();
				this.resetForm();
				this.dataForm.fill(response.data);
				this.dataForm.mode = 'edit';
				this.formDataTitle = 'Edit '+this.title;
				this.getProvinces(response.data.province_id);
				this.getRegencies(response.data.province_id, response.data.regency_id);
				this.getDistricts(response.data.regency_id, response.data.district_id);
				this.getVillages(response.data.district_id, response.data.village_id);
				this.dialog.formData = true;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		openDelete(mode = null, data = null){
			if(mode == 'single'){
				this.dataTable.selectedData = [];
				this.dataTable.selectedData.push(data);
			}
			this.dialog.deleteData = true;
		},
		resetForm(){
			this.dataForm.clear();
			this.dataForm.reset();
		},
		submitData(){
			this.$Progress.start();
			if(this.dataForm.mode == 'add'){
				this.dataForm.post(this.api)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			} else if(this.dataForm.mode == 'edit') {
				this.dataForm.put(this.api + '/' + this.dataForm.id)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			}
		},
		deleteData() {
			this.$Progress.start();
			this.dialog.deleteData = false;
			const selectedId = this.dataTable.selectedData.map(value => value.id);
			this.$httpAuth.delete(this.api + '/delete', {
				params: {
					id: selectedId
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	created(){
		this.$e.emit('updateTitle', this.title);

		// this.dataTable.selectedColumns = this.dataTable.columns;
	},
	mounted() {
		this.loadData();
		this.getProvinces(null, true);
		(this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.province) ? this.getRegencies(this.$store.getters.regionalUser.province.id, null, true) : null;
		(this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.regency) ? this.getDistricts(this.$store.getters.regionalUser.regency.id, null, true) : null;
		(this.$store.getters.isRegionalUser && this.$store.getters.regionalUser.district) ? this.getVillages(this.$store.getters.regionalUser.district.id, null, true) : null;
		this.getStatus();
	},
}
</script>